/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateIntelOrg = /* GraphQL */ `
  subscription OnCreateIntelOrg($filter: ModelSubscriptionIntelOrgFilterInput) {
    onCreateIntelOrg(filter: $filter) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      groups {
        items {
          id
          title
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          website
          phone
          intelOrgId
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          metrics {
            title
            ranking
            sales
            active_advisors
            leader_percentage
            trend
            total_prospects
            new_prospects
            __typename
          }
          aum
          recProductsCount
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        id
        title
        type
        image
        excerpt
        tags
        active
        action_text
        __typename
      }
      website
      phone
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateIntelOrg = /* GraphQL */ `
  subscription OnUpdateIntelOrg($filter: ModelSubscriptionIntelOrgFilterInput) {
    onUpdateIntelOrg(filter: $filter) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      groups {
        items {
          id
          title
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          website
          phone
          intelOrgId
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          metrics {
            title
            ranking
            sales
            active_advisors
            leader_percentage
            trend
            total_prospects
            new_prospects
            __typename
          }
          aum
          recProductsCount
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        id
        title
        type
        image
        excerpt
        tags
        active
        action_text
        __typename
      }
      website
      phone
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteIntelOrg = /* GraphQL */ `
  subscription OnDeleteIntelOrg($filter: ModelSubscriptionIntelOrgFilterInput) {
    onDeleteIntelOrg(filter: $filter) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      groups {
        items {
          id
          title
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          website
          phone
          intelOrgId
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          metrics {
            title
            ranking
            sales
            active_advisors
            leader_percentage
            trend
            total_prospects
            new_prospects
            __typename
          }
          aum
          recProductsCount
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        id
        title
        type
        image
        excerpt
        tags
        active
        action_text
        __typename
      }
      website
      phone
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateIntelGroup = /* GraphQL */ `
  subscription OnCreateIntelGroup(
    $filter: ModelSubscriptionIntelGroupFilterInput
  ) {
    onCreateIntelGroup(filter: $filter) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      website
      phone
      intelOrgId
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      metrics {
        title
        ranking
        sales
        active_advisors
        leader_percentage
        trend
        total_prospects
        new_prospects
        __typename
      }
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateIntelGroup = /* GraphQL */ `
  subscription OnUpdateIntelGroup(
    $filter: ModelSubscriptionIntelGroupFilterInput
  ) {
    onUpdateIntelGroup(filter: $filter) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      website
      phone
      intelOrgId
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      metrics {
        title
        ranking
        sales
        active_advisors
        leader_percentage
        trend
        total_prospects
        new_prospects
        __typename
      }
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteIntelGroup = /* GraphQL */ `
  subscription OnDeleteIntelGroup(
    $filter: ModelSubscriptionIntelGroupFilterInput
  ) {
    onDeleteIntelGroup(filter: $filter) {
      id
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      website
      phone
      intelOrgId
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      metrics {
        title
        ranking
        sales
        active_advisors
        leader_percentage
        trend
        total_prospects
        new_prospects
        __typename
      }
      aum
      recProductsCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onCreateOrganization(filter: $filter) {
      id
      name
      logo {
        bucket
        region
        key
        __typename
      }
      active
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      phone
      createdAt
      updatedAt
      accessCode
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onUpdateOrganization(filter: $filter) {
      id
      name
      logo {
        bucket
        region
        key
        __typename
      }
      active
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      phone
      createdAt
      updatedAt
      accessCode
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onDeleteOrganization(filter: $filter) {
      id
      name
      logo {
        bucket
        region
        key
        __typename
      }
      active
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      phone
      createdAt
      updatedAt
      accessCode
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            logo {
              bucket
              region
              key
              __typename
            }
            active
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            accessCode
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateAdvisor = /* GraphQL */ `
  subscription OnCreateAdvisor($filter: ModelSubscriptionAdvisorFilterInput) {
    onCreateAdvisor(filter: $filter) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
        __typename
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      showAdvisorCard
      prospectCriteria
      prospects {
        items {
          id
          type
          sendReport
          firstName
          lastName
          email
          phone
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          advisorNotified
          advisorVanityName
          isAnonymous
          lastScoreStep
          subscribed
          scoreSurveyAnswers
          scoreSurveyComplete
          scoreSurveyResults
          scoreSurveySource
          scoreAgreeTerms
          scoreContactComplete
          createdAt
          updatedAt
          origin
          qualified
          ctaRequests {
            id
            name
            createdAt
            updatedAt
            source
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      vanityNames {
        items {
          id
          createdAt
          vanityName
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notificationEmails
      disclosure
      intelGroupId
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      surveyQuestions
      collectContactPostSurvey
      organizationId
      organization {
        id
        name
        logo {
          bucket
          region
          key
          __typename
        }
        active
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        accessCode
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateAdvisor = /* GraphQL */ `
  subscription OnUpdateAdvisor($filter: ModelSubscriptionAdvisorFilterInput) {
    onUpdateAdvisor(filter: $filter) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
        __typename
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      showAdvisorCard
      prospectCriteria
      prospects {
        items {
          id
          type
          sendReport
          firstName
          lastName
          email
          phone
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          advisorNotified
          advisorVanityName
          isAnonymous
          lastScoreStep
          subscribed
          scoreSurveyAnswers
          scoreSurveyComplete
          scoreSurveyResults
          scoreSurveySource
          scoreAgreeTerms
          scoreContactComplete
          createdAt
          updatedAt
          origin
          qualified
          ctaRequests {
            id
            name
            createdAt
            updatedAt
            source
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      vanityNames {
        items {
          id
          createdAt
          vanityName
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notificationEmails
      disclosure
      intelGroupId
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      surveyQuestions
      collectContactPostSurvey
      organizationId
      organization {
        id
        name
        logo {
          bucket
          region
          key
          __typename
        }
        active
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        accessCode
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteAdvisor = /* GraphQL */ `
  subscription OnDeleteAdvisor($filter: ModelSubscriptionAdvisorFilterInput) {
    onDeleteAdvisor(filter: $filter) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
        __typename
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      showAdvisorCard
      prospectCriteria
      prospects {
        items {
          id
          type
          sendReport
          firstName
          lastName
          email
          phone
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          advisorNotified
          advisorVanityName
          isAnonymous
          lastScoreStep
          subscribed
          scoreSurveyAnswers
          scoreSurveyComplete
          scoreSurveyResults
          scoreSurveySource
          scoreAgreeTerms
          scoreContactComplete
          createdAt
          updatedAt
          origin
          qualified
          ctaRequests {
            id
            name
            createdAt
            updatedAt
            source
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      vanityNames {
        items {
          id
          createdAt
          vanityName
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notificationEmails
      disclosure
      intelGroupId
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      surveyQuestions
      collectContactPostSurvey
      organizationId
      organization {
        id
        name
        logo {
          bucket
          region
          key
          __typename
        }
        active
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        accessCode
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              logo {
                bucket
                region
                key
                __typename
              }
              active
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              accessCode
              advisors {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateAdvisorVanityName = /* GraphQL */ `
  subscription OnCreateAdvisorVanityName(
    $filter: ModelSubscriptionAdvisorVanityNameFilterInput
  ) {
    onCreateAdvisorVanityName(filter: $filter) {
      id
      createdAt
      vanityName
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAdvisorVanityName = /* GraphQL */ `
  subscription OnUpdateAdvisorVanityName(
    $filter: ModelSubscriptionAdvisorVanityNameFilterInput
  ) {
    onUpdateAdvisorVanityName(filter: $filter) {
      id
      createdAt
      vanityName
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAdvisorVanityName = /* GraphQL */ `
  subscription OnDeleteAdvisorVanityName(
    $filter: ModelSubscriptionAdvisorVanityNameFilterInput
  ) {
    onDeleteAdvisorVanityName(filter: $filter) {
      id
      createdAt
      vanityName
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onCreateProspect = /* GraphQL */ `
  subscription OnCreateProspect($filter: ModelSubscriptionProspectFilterInput) {
    onCreateProspect(filter: $filter) {
      id
      type
      sendReport
      firstName
      lastName
      email
      phone
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      advisorNotified
      advisorVanityName
      isAnonymous
      lastScoreStep
      subscribed
      scoreSurveyAnswers
      scoreSurveyComplete
      scoreSurveyResults
      scoreSurveySource
      scoreAgreeTerms
      scoreContactComplete
      createdAt
      updatedAt
      origin
      qualified
      ctaRequests {
        id
        name
        createdAt
        updatedAt
        source
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateProspect = /* GraphQL */ `
  subscription OnUpdateProspect($filter: ModelSubscriptionProspectFilterInput) {
    onUpdateProspect(filter: $filter) {
      id
      type
      sendReport
      firstName
      lastName
      email
      phone
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      advisorNotified
      advisorVanityName
      isAnonymous
      lastScoreStep
      subscribed
      scoreSurveyAnswers
      scoreSurveyComplete
      scoreSurveyResults
      scoreSurveySource
      scoreAgreeTerms
      scoreContactComplete
      createdAt
      updatedAt
      origin
      qualified
      ctaRequests {
        id
        name
        createdAt
        updatedAt
        source
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteProspect = /* GraphQL */ `
  subscription OnDeleteProspect($filter: ModelSubscriptionProspectFilterInput) {
    onDeleteProspect(filter: $filter) {
      id
      type
      sendReport
      firstName
      lastName
      email
      phone
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          logo {
            bucket
            region
            key
            __typename
          }
          active
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          accessCode
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                active
                url
                phone
                createdAt
                updatedAt
                accessCode
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      advisorNotified
      advisorVanityName
      isAnonymous
      lastScoreStep
      subscribed
      scoreSurveyAnswers
      scoreSurveyComplete
      scoreSurveyResults
      scoreSurveySource
      scoreAgreeTerms
      scoreContactComplete
      createdAt
      updatedAt
      origin
      qualified
      ctaRequests {
        id
        name
        createdAt
        updatedAt
        source
        __typename
      }
      __typename
    }
  }
`;
export const onCreateReportTemplate = /* GraphQL */ `
  subscription OnCreateReportTemplate(
    $filter: ModelSubscriptionReportTemplateFilterInput
  ) {
    onCreateReportTemplate(filter: $filter) {
      id
      createdAt
      updatedAt
      template
      version
      name
      data
      current
      __typename
    }
  }
`;
export const onUpdateReportTemplate = /* GraphQL */ `
  subscription OnUpdateReportTemplate(
    $filter: ModelSubscriptionReportTemplateFilterInput
  ) {
    onUpdateReportTemplate(filter: $filter) {
      id
      createdAt
      updatedAt
      template
      version
      name
      data
      current
      __typename
    }
  }
`;
export const onDeleteReportTemplate = /* GraphQL */ `
  subscription OnDeleteReportTemplate(
    $filter: ModelSubscriptionReportTemplateFilterInput
  ) {
    onDeleteReportTemplate(filter: $filter) {
      id
      createdAt
      updatedAt
      template
      version
      name
      data
      current
      __typename
    }
  }
`;
export const onCreateScoreSurveyQuestion = /* GraphQL */ `
  subscription OnCreateScoreSurveyQuestion(
    $filter: ModelSubscriptionScoreSurveyQuestionFilterInput
  ) {
    onCreateScoreSurveyQuestion(filter: $filter) {
      id
      createdAt
      updatedAt
      componentName
      title
      description
      icon
      disabled
      valid
      value
      active
      type
      helpText {
        label
        text
        __typename
      }
      scoringParam
      category
      options
      skipFocus
      test
      __typename
    }
  }
`;
export const onUpdateScoreSurveyQuestion = /* GraphQL */ `
  subscription OnUpdateScoreSurveyQuestion(
    $filter: ModelSubscriptionScoreSurveyQuestionFilterInput
  ) {
    onUpdateScoreSurveyQuestion(filter: $filter) {
      id
      createdAt
      updatedAt
      componentName
      title
      description
      icon
      disabled
      valid
      value
      active
      type
      helpText {
        label
        text
        __typename
      }
      scoringParam
      category
      options
      skipFocus
      test
      __typename
    }
  }
`;
export const onDeleteScoreSurveyQuestion = /* GraphQL */ `
  subscription OnDeleteScoreSurveyQuestion(
    $filter: ModelSubscriptionScoreSurveyQuestionFilterInput
  ) {
    onDeleteScoreSurveyQuestion(filter: $filter) {
      id
      createdAt
      updatedAt
      componentName
      title
      description
      icon
      disabled
      valid
      value
      active
      type
      helpText {
        label
        text
        __typename
      }
      scoringParam
      category
      options
      skipFocus
      test
      __typename
    }
  }
`;
