<template>
  <div class="">
    <v-snackbar
      v-model="showSnackBar"
      :timeout="2000"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      right
      tile
    >
      <div class="text-center">{{ snackBarText }}</div>
    </v-snackbar>

    <h1 class="mt-5">Prospects Management</h1>
    <!-- <p class="red--text darken-4">PLANGAP PERSONNEL ONLY!</p> -->
    <!-- <p>Notes:</p>
    <ul>
      <li>
        Click on Survey Complete check mark to copy the prospects score report
        link.
      </li>
      <li>Click on answers value to see raw answers object.</li>
    </ul> -->
    <v-row class="my-8">
      <v-col class="col">
        <v-data-table
          v-if="items"
          :loading="tableLoading"
          loading-text="Loading Prospects... Please wait"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="serverItemsLength"
          :footer-props="{
            itemsPerPageOptions: itemsPerPage,
          }"
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-toolbar flat class="mb-4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
                @input="fetchData"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-data-footer
                :pagination="pagination"
                :options.sync="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="itemsPerPage"
              />
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr :class="{ 'is-complete': item.scoreSurveyComplete }">
              <td>
                <small class="d-block">{{ item.id ? item.id : "No ID" }}</small>
              </td>
              <td>
                <small>{{ item.email ? item.email : "--" }}</small>
              </td>
              <td>
                <small>{{ item.firstName ? item.firstName : "--" }}</small>
              </td>
              <td>
                <small>
                  {{ item.lastName ? item.lastName : "--" }}
                </small>
              </td>
              <td class="text-center">
                <a v-if="item.phone" :href="`tel: ${item.phone}`"
                  ><small>{{ item.phone }}</small></a
                >
                <template v-else>--</template>
              </td>
              <td>
                <small class="d-block">
                  {{ item.createdAt ? formatDate(item.createdAt) : "--" }}
                </small>
              </td>
              <td class="text-center">
                <!-- <a
                  v-if="item.advisor"
                  href="#"
                  @click.prevent="handleOpenAdvisor(item)"
                  class="text-decoration-none"
                  >{{ item.advisor.vanityNames.items[0].vanityName
                  }}<br /><small>{{ item.advisorId }}</small></a
                > -->
                <template v-if="item.advisor">
                  <router-link
                    :to="{ path: '/admin/advisor/edit/' + item.advisor.id }"
                    target="_blank"
                  >
                    <small
                      >{{ item.advisor.firstName }} {{ item.advisor.lastName
                      }}<br
                    /></small>
                  </router-link>
                  <small class="d-block grey--text text--darken-1">{{
                    item.advisor.id
                  }}</small>
                </template>

                <span v-else>--</span>
              </td>
              <td class="text-center">
                <v-icon v-if="item.scoreSurveyComplete" color="success"
                  >mdi-check-bold</v-icon
                >
                <span v-else>--</span>
              </td>
              <td class="text-center">
                <a
                  href="#"
                  v-if="item.scoreSurveyAnswers"
                  @click.prevent="handleOpenAnswers(item)"
                  class="text-decoration-none"
                  >{{ JSON.parse(item.scoreSurveyAnswers).length }}</a
                >
                <span v-else>--</span>
              </td>

              <td class="text-center">
                <div class="d-flex justify-center align-center">
                  <v-tooltip top nudge-bottom="5">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click.prevent="handleCopyLink(item.id)"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        class="mr-1 text-center text-decoration-none"
                        :disabled="!item.scoreSurveyComplete"
                        ><v-icon small>mdi-content-copy</v-icon></v-btn
                      >
                    </template>
                    <span>Copy</span>
                  </v-tooltip>

                  <v-tooltip top nudge-bottom="5">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="getLink(linkPath, item.id)"
                        v-bind="attrs"
                        v-on="on"
                        target="_blank"
                        icon
                        small
                        class="mr-1 text-center text-decoration-none"
                        :disabled="!item.scoreSurveyComplete"
                        ><v-icon small>mdi-open-in-new</v-icon></v-btn
                      >
                    </template>
                    <span>Open</span>
                  </v-tooltip>

                  <v-tooltip top nudge-bottom="5">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click.prevent="handleOpenDeleteProspect(item)"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        class="text-center text-decoration-none"
                      >
                        <v-icon color="red" small> mdi-delete </v-icon></v-btn
                      >
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogAnswers" scrollable width="900px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Prospect Survey Answers</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <div class="mb-8">
              Prospect: <small>{{ prospect.id }}</small>
            </div>
            <pre>
                          {{
                prospect.scoreSurveyAnswers
                  ? JSON.parse(prospect.scoreSurveyAnswers)
                  : "No Data"
              }}
                      </pre
            >
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAnswers">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAdvisor" scrollable width="900px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Prospect Advisor</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <div class="mb-8">
              Prospect: <small>{{ prospect.id }}</small>
            </div>
            <p>Advisor:</p>
            <pre>{{ prospect.advisor ? prospect.advisor : "No Data" }}</pre>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAdvisor">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card color="danger">
        <v-card-title class="text-h6"
          >Are you sure you want to
          <span class="red--text">&nbsp; DELETE &nbsp; </span>
          prospect: <small>{{ prospect.id }}</small
          >?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="handleDeleteProspect"
            >DELETE</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { v4 as uuidv4 } from "uuid";
import { API, graphqlOperation } from "aws-amplify";
// import { searchProspects } from "@/graphql/queries";
import { deleteProspect } from "@/graphql/mutations";
import {
  onCreateProspect,
  onDeleteProspect,
  onUpdateProspect,
} from "@/graphql/subscriptions";
import linkBuilderMixin from "@/mixins/linkBuilderMixin";
import tableSearchPaginateMixin from "@/mixins/tableSearchPaginateMixin";

const initialProspect = function () {
  return {
    name: "",
    email: "",
  };
};

export default {
  name: "AdminProspectManagement",
  components: {},
  mixins: [linkBuilderMixin, tableSearchPaginateMixin],
  data() {
    return {
      queryContext: "prospects",
      prospect: new initialProspect(),
      dialogAdvisor: false,
      dialogAnswers: false,
      dialogDelete: false,
      editedIndex: -1,
      headers: [
        {
          text: "Prospect ID",
          align: "start",
          value: "id",
        },
        { text: "Email", value: "email" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Phone", value: "phone" },
        {
          text: "Created",
          align: "start",
          value: "createdAt",
        },
        { text: "Advisor", value: "advisorId", align: "center" },
        {
          text: "Survey Complete",
          value: "scoreSurveyComplete",
          align: "center",
        },
        {
          text: "Answers",
          value: "scoreSurveyAnswers",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "View Results",
        //   value: "scoreSurveyResults",
        //   align: "center",
        //   sortable: false,
        // },
        // {
        //   text: "Score Results",
        //   value: "scoreSurveyResults",
        //   sortable: false,
        // },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      showSnackBar: false,
      snackBarText: "",
      snackBarColor: "info",
    };
  },
  computed: {
    linkPath() {
      return process.env.VUE_APP_REPORT_LINK_PARAM;
    },
  },
  created() {
    //Subscribe to changes
    API.graphql(graphqlOperation(onCreateProspect)).subscribe((sourceData) => {
      const newProspect = sourceData.value.data.onCreateProspect;
      if (newProspect && this.items) {
        // skip our own mutations and duplicates
        if (this.items.some((r) => r.id == newProspect.id)) return;
        this.items = [newProspect, ...this.items];
      }
    });
    API.graphql(graphqlOperation(onUpdateProspect)).subscribe((sourceData) => {
      const updatedProspect = sourceData.value.data.onUpdateProspect;
      if (updatedProspect && this.items) {
        console.log("there was an update");
        if (this.items.some((r) => r.id == updatedProspect.id)) {
          console.log("this is the prospect to update", updatedProspect.id);

          const index = this.items.findIndex(
            (p) => p.id === updatedProspect.id
          );
          Object.assign(this.items[index], updatedProspect);
        }

        // this.items = [updatedProspect, ...this.items];
        // this.items = this.items.filter(
        //   (r) => r.id != updatedProspect.id
        // );
      }
    });
    API.graphql(graphqlOperation(onDeleteProspect)).subscribe((sourceData) => {
      const deletedProspect = sourceData.value.data.onDeleteProspect;
      if (deletedProspect && this.items) {
        this.items = this.items.filter((r) => r.id != deletedProspect.id);
      }
    });
  },
  methods: {
    async handleDeleteProspect() {
      const prospect = this.prospect;

      if (prospect) {
        try {
          const { id } = prospect;
          // console.log("Prospect to delete!", prospect);
          await API.graphql(
            graphqlOperation(deleteProspect, {
              input: { id: id },
            })
          );
          // console.log("Prospect deleted!");
          this.items = this.items.filter((r) => r.id != prospect.id);

          this.showSnackBar = true;
          this.snackBarColor = "success";
          this.snackBarText = `Prospect has been DELETED!`;

          this.closeDelete();
        } catch (error) {
          console.log("Error deleting prospect...", error);
        }
      }
    },
    handleOpenDeleteProspect(item) {
      this.editedIndex = this.items.indexOf(item);
      this.prospect = Object.assign({}, item);
      this.dialogDelete = true;
    },
    handleOpenAdvisor(advisorId) {
      // this.prospect = Object.assign({}, item);
      // this.dialogAdvisor = true;
      this.$router.push("/admin/advisor/edit/" + advisorId);
    },
    handleOpenAnswers(item) {
      this.prospect = Object.assign({}, item);
      this.dialogAnswers = true;
    },
    closeAdvisor() {
      this.dialogAdvisor = false;
      this.$nextTick(() => {
        this.prospect = Object.assign({}, new initialProspect());
      });
    },
    closeAnswers() {
      this.dialogAnswers = false;
      this.$nextTick(() => {
        this.prospect = Object.assign({}, new initialProspect());
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.prospect = Object.assign({}, new initialProspect());
        this.editedIndex = -1;
      });
    },
    async handleCopyLink(id) {
      try {
        const path = this.linkPath;
        const url = this.copyLink(path, id);

        if (url) {
          this.linkCopied = true;

          this.showSnackBar = true;
          this.snackBarText = "Prospect's Report Link Copied!";
          this.snackBarColor = "info";
        }
        setTimeout(() => {
          this.linkCopied = false;
        }, 3000);
      } catch ($e) {
        this.linkCopied = false;
      } finally {
        this.dialogVanityNames = false;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("default", {
        dateStyle: "medium",
        timeStyle: "short",
      }).format(date);
    },
  },
};
</script>

<style lang="scss">
.v-alert {
  a {
    word-break: break-all;
  }
}

.is-complete {
  background-color: rgb(235, 255, 246);
}

pre {
  white-space: pre-line;
}
</style>
